<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>{{ $t('i18nn_0f864f84a5e810d6') }}</span>

			</h3>
		</div> -->
		
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_0f864f84a5e810d6')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
						  <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		
		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			
			<div class="account-top-con">
				<div class="account-info-con">
					<div class="account-info-main">
						<div class="account-info-name">{{$t('i18nn_b55874f01c0bc221')}}</div>
						<div class="account-info-val">
							<span class="account-info-val-num">{{ tableData.useAmt }}</span>
							<span class="account-info-val-unit">$</span>
						</div>
					</div>
					<div class="account-info-other">
						<el-descriptions title="" :column="2" size="small">
						    <el-descriptions-item label="UserID">{{ tableData.userId }}</el-descriptions-item>
						    <el-descriptions-item label="ID">{{ tableData.id }}</el-descriptions-item>
						    <el-descriptions-item label="Api Key">{{ tableKeyData }}</el-descriptions-item>
						</el-descriptions>
					</div>
				</div>
				<div class="account-recharge-con">
					<div class="account-recharge">
						<div class="account-recharge-btn">
							<el-button type="primary" icon="el-icon-bank-card" @click="openDioalog()">{{$t('i18nn_51fd0a603ed58073')}}</el-button>
						</div>
						<div class="account-recharge-record">
							
							<router-link :to="{ name: 'RechargeRecord' }">
								<span>{{$t('i18nn_a9c4ab3208bd8aab')}}</span>&nbsp;<i class="el-icon-right"></i>
							</router-link>
						</div>
					</div>
					<div class="account-recharge-tips">
						<h3>{{$t('i18nn_61bd9a8fcc2ea788')}}</h3>
						<p>{{$t('tips.long_accountInfo')}}</p>
					</div>
				</div>
			</div>
			
			<!-- <el-card style="font-size: 16px;line-height: 200%; margin-bottom: 10px;">
				<div slot="header">
					<span>{{ $t('i18nn_0f864f84a5e810d6') }}</span>
				</div>
				<div>
					<span>{{ $t('i18nn_004a25fe67f297c3') }}</span>
					：
					<span>{{ tableData.useAmt }}</span>
				</div>
				<el-button type="primary" icon="el-icon-bank-card" @click="$router.push({ name: 'RechargeRecord' })">{{ $t('i18nn_831ab31568a78285') }}</el-button>
			</el-card>

			<el-card style="font-size: 14px;line-height: 200%;">
				<div slot="header"><span>API KEY</span></div>
				<div>
					<el-tag>{{ tableKeyData }}</el-tag>
				</div>
			</el-card> -->
			<el-card :shadow="'never'" style="margin-top: 5px;" :body-style="{padding:'0px 20px'}">
				<!-- <div slot="header">
					<span>{{$t('i18nn_c4ac0560611963af')}}</span>
				</div> -->
				<expenseRecord></expenseRecord>
			</el-card>
		</div>
		
		<!-- 充值，新增编辑 -->
		<RechargeRecordAdd :openTime="openTime" :openRow="openRow" @postSuccess="postSuccess()"></RechargeRecordAdd>
	</div>
</template>
<script>
// import JQ from 'jquery'
// import CommodityCateLinkage from '@/components/Common/CommodityCateLinkage.vue';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
// import barcode from '@/components/Common/barcode.vue';
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import expenseRecord from '@/components/StorageCenter/account/expenseRecord.vue';
import RechargeRecordAdd from '@/components/StorageCenter/account/RechargeRecordAdd.vue';

export default {
	components: {
		expenseRecord,
		RechargeRecordAdd
	},
	// props: {
	// 	// mobile:"",
	// 	isSel: {
	// 		default: function() {
	// 			return false;
	// 		},
	// 		type: Boolean
	// 	},
	// 	status: {
	// 		default: function() {
	// 			return '';
	// 		},
	// 		type: String
	// 	}
	// },
	data() {
		return {
			
			loading: false,

			loading_load: false,
			tableData: {},

			tableKeyData: '',
			
			openTime:"",
			openRow:{},
			
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		this.initData();
	},
	methods: {
		initData() {
			// this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getDetData();
			this.getKeyDetData();
			// this.getDicData();
		},

		//查询数据
		// serPageData() {
		// 	// this.pagination.current_page = 1;
		// 	this.getDetData();
		// },
		//打开新增编辑，弹窗
		openDioalog(formParm) {
			this.openTime = new Date().getTime();
			this.openRow = {};
		},
		postSuccess(){
			this.$router.push({name:"RechargeRecord"});
		},
		
		//请求分页数据
		getDetData() {
			// let _this = this;
			this.loading_load = true;
			this.$http
				.get(this.$urlConfig.WhAccountUser, {
					// sortAsc: this.filterData.sortAsc,
					// orderBy: this.filterData.orderBy,
					// offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					// limit: this.pagination.page_size //当前页显示数目
					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					//      // status: this.filterData.status ? this.filterData.status : null,
					//      subjectName: this.filterData.subjectName ? this.filterData.subjectName : null,
					//      goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					//      // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					//      goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					//      goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					//      keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				})
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log(this.$t('i18nn_776496ec446f21f9'));
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.data;
					//当前数据总条数
					// this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		//请求分页数据
		getKeyDetData() {
			// let _this = this;
			this.loading_load = true;
			this.$http
				.get(this.$urlConfig.WhAccountApiKey, {})
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log(this.$t('i18nn_776496ec446f21f9'));
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableKeyData = data.data;
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		}

		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_size_unit'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_size_unit = data.data['wh_size_unit'];
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import url(@/assets/css/accountInfo.less);
</style>
